import React from "react";

//import HighlightIcon from "@material-ui/icons/Highlight";

function Header() {
  return (
    <header>
      <h1>
        California COVID-19
      </h1>
    </header>
  );
}

export default Header;
